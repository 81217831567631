const THEME_NAME_SEPARATOR = "_",
  THEME_CLASSNAME_PREFIX = "t_",
  stackDefaultStyles = {},
  webViewFlexCompatStyles = {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    flexBasis: "auto",
    boxSizing: "border-box",
    position: "relative",
    minHeight: 0,
    minWidth: 0,
    flexShrink: 0
  };
Object.assign(stackDefaultStyles, webViewFlexCompatStyles);
export { THEME_CLASSNAME_PREFIX, THEME_NAME_SEPARATOR, stackDefaultStyles, webViewFlexCompatStyles };