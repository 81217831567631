import { isWeb } from "@tamagui/constants";
import { simpleHash } from "@tamagui/helpers";
import { getConfig } from "./config.mjs";
const IS_VAR = "isVar",
  createVariable = (props, skipHash = !1) => {
    if (!skipHash && isVariable(props)) return props;
    const {
      key,
      name,
      val
    } = props;
    return {
      [IS_VAR]: !0,
      key,
      name: skipHash ? "" : simpleHash(name, 40),
      val,
      variable: isWeb ? skipHash ? `var(--${name})` : createCSSVariable(name) : ""
    };
  };
function variableToString(vrble, getValue = !1) {
  return isVariable(vrble) ? !getValue && isWeb && vrble.variable ? vrble.variable : `${vrble.val}` : `${vrble || ""}`;
}
function isVariable(v) {
  return v && typeof v == "object" && IS_VAR in v;
}
function getVariable(nameOrVariable) {
  if (setDidGetVariableValue(!0), isVariable(nameOrVariable)) return variableToString(nameOrVariable);
  const tokens = getConfig().tokensParsed;
  return variableToString(tokens[nameOrVariable] ?? nameOrVariable);
}
let accessed = !1;
const setDidGetVariableValue = val => accessed = val,
  didGetVariableValue = () => accessed;
function getVariableValue(v) {
  return isVariable(v) ? (setDidGetVariableValue(!0), v.val) : v;
}
function getVariableName(v) {
  return isVariable(v) ? v.name : v;
}
function getVariableVariable(v) {
  return isVariable(v) ? v.variable : v;
}
const createCSSVariable = (nameProp, includeVar = !0) => {
  if (process.env.NODE_ENV === "development" && (!nameProp || typeof nameProp != "string")) throw new Error(`createCSSVariable expected string, got: ${nameProp}`);
  const name = simpleHash(nameProp, 60);
  return includeVar ? `var(--${name})` : name;
};
export { createCSSVariable, createVariable, didGetVariableValue, getVariable, getVariableName, getVariableValue, getVariableVariable, isVariable, setDidGetVariableValue, variableToString };